export default {
  formConfig: {
    titleWidth: 100,
    titleAlign: 'right',
    item: [
      // {
      //   field: 'customerCode',
      //   search: 'true',
      //   title: '关联客户',
      //   type: 'select',
      //   apiUrl: '/mdm/mdmCustomerMsgController/findCustomerNotRelateUserSelectList',
      //   optionsKey: {
      //     label: 'customerName',
      //     value: 'customerCode',
      //   },
      //   isSearch: true,
      //   paramsName: 'customerName',
      //   paramsFixed: {
      //     enableStatus: '009',
      //   },
      // },
      // {
      //   field: 'positionLevelCode',
      //   search: 'true',
      //   title: '职位级别',
      //   type: 'select',
      //   apiUrl: '/mdm/mdmPositionLevelController/selectList',
      //   optionsKey: {
      //     label: 'positionLevelName',
      //     value: 'positionLevelCode',
      //   },
      //   paramsFixed: {
      //     enableStatus: '009',
      //   },
      // },
      {
        field: 'terminalCode',
        search: 'true',
        isSearch: true,
        paramsName: 'terminalName',
        title: '终端',
        type: 'select',
        apiUrl: '/mdm/mdmTerminalUserController/getTerminalListByCondition',
        optionsKey: {
          label: 'terminalName',
          value: 'terminalCode',
        },
        paramsFixed: {
          enableStatus: '009',
        },
      },
    ],
  },
  toolBarConfig: [],
  columns: [],
  tableConfig: {
    border: true,
    // treeConfig: {
    //   children: 'children',
    // },
    idKey: 'functionCode', // 默认勾选判断字段
    resizable: true, // 所有的列是否允许拖动列宽调整大小
    showOverflow: true, // 当内容过长时显示为省略号
    keepSource: false, // 保持原始值的状态，被某些功能所依赖，比如编辑状态、还原数据等（开启后影响性能，具体取决于数据量）
    highlightHoverRow: true, // 鼠标滑动高亮
    // height: '600',
  },
};
