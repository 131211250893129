var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addUser-position-information" },
    [
      _c(
        "el-form",
        {
          ref: "infoObjFormRef",
          attrs: {
            disabled: _vm.formConfig.btnCode === "view",
            model: _vm.infoObj,
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _c(
            "div",
            _vm._l(_vm.infoObj.terminalList, function (item, index) {
              return _c(
                "el-row",
                { key: index },
                [
                  _c("el-col", { attrs: { span: 24 } }, [
                    _c("div", { staticClass: "arrItemTitle" }, [
                      _c("div", { staticClass: "arrItemTitle-left" }, [
                        _c("p", [
                          _vm._v(" 终端信息" + _vm._s(index + 1) + " "),
                        ]),
                        !(_vm.formConfig.btnCode === "view")
                          ? _c(
                              "div",
                              [
                                index === 0
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addArrItem(
                                              _vm.infoObj.userType,
                                              _vm.infoObj.terminalList
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "el-icon-circle-plus-outline",
                                          staticStyle: { "font-size": "20px" },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delArrItem(
                                          _vm.infoObj.userType,
                                          _vm.infoObj.terminalList,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "el-icon-delete",
                                      staticStyle: { "font-size": "20px" },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c("div", { staticClass: "arrItemTitle-right" }),
                    ]),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "terminalList." + index + ".terminalCode",
                            rules: {
                              required: true,
                              message: "关联终端不能为空",
                              trigger: "change",
                            },
                            label: "关联终端",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                remote: "",
                                "remote-method": function (name) {
                                  _vm.getItemterminalCodeOptionArr(
                                    name,
                                    item.terminalCode,
                                    index
                                  )
                                },
                                filterable: "",
                                clearable: "",
                              },
                              on: {
                                focus: function ($event) {
                                  return _vm.getItemterminalCodeOptionArr(
                                    "",
                                    item.terminalCode,
                                    index
                                  )
                                },
                              },
                              model: {
                                value: item.terminalCode,
                                callback: function ($$v) {
                                  _vm.$set(item, "terminalCode", $$v)
                                },
                                expression: "item.terminalCode",
                              },
                            },
                            _vm._l(
                              item.itemterminalCodeOptionArr,
                              function (item2, index2) {
                                return _c("el-option", {
                                  key: index2,
                                  attrs: {
                                    label: item2.label,
                                    value: item2.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }