<template>
  <div>
    <el-form ref="infoObjFormRef" :rules="rules" :model="infoObj">
      <el-form-item label="新密码" prop="password" class="flex">
        <Password v-model="infoObj.password" placeholder="密码"></Password>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import Password from '../../../../../../../components/form/components/form_password.vue';

export default {
  props: {
    value: Object,
  },
  components: {
    Password,
  },
  data() {
    return {
      modalConfig: {
        title: '重置密码',
        visible: false,
        type: 'Modal',
        width: '600px',
        height: '200px',
      },
      rules: {
        password: [{ required: true, message: '新密码不能为空', trigger: 'blur' }],
      },
      infoObj: {
        password: '',
      },
    };
  },
  created() {
    this.$emit('input', this.infoObj);
  },
  methods: {
    closeModal() {
      this.modalConfig.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
  .flex{
    display: flex;
  }
  /deep/ .el-form-item__content{
    flex: 1;
  }
</style>
