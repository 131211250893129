<template>
  <div class="addUser-position-information">
    <el-form :disabled="formConfig.btnCode === 'view'" :model="infoObj" label-position="right" ref="infoObjFormRef" label-width="100px">
      <div>
        <el-row v-for="(item,index) in infoObj.terminalList" :key="index">
          <el-col :span="24">
            <div class="arrItemTitle">
              <div class="arrItemTitle-left">
                <p>
                  终端信息{{index + 1}}
                </p>
                <div v-if="!(formConfig.btnCode === 'view')">
                  <el-button type="text" v-if="index === 0" @click="addArrItem(infoObj.userType,infoObj.terminalList)">
                    <i style="font-size: 20px" class="el-icon-circle-plus-outline"></i>
                  </el-button>
                  <el-button type="text"
                             @click="delArrItem(infoObj.userType,infoObj.terminalList,index)">
                    <i style="font-size: 20px" class="el-icon-delete"></i>
                  </el-button>
                </div>

              </div>
              <div class="arrItemTitle-right">

              </div>
            </div>
          </el-col>

          <el-col :span="8">
            <el-form-item
              :prop="'terminalList.' + index + '.terminalCode'"
              :rules="{required: true, message: '关联终端不能为空', trigger: 'change'}"
              label="关联终端">
              <el-select remote :remote-method="(name)=>{getItemterminalCodeOptionArr(name,item.terminalCode,index)}"
                         @focus="getItemterminalCodeOptionArr('',item.terminalCode,index)"
                          filterable clearable v-model="item.terminalCode" >
                <el-option v-for="(item2,index2) in item.itemterminalCodeOptionArr" :key="index2" :label="item2.label"
                           :value="item2.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <!-- <el-col :span="8">
            <el-form-item filterable label="角色">
              <el-select remote :remote-method="(name)=>{getItemRoleCodeOptionArr(name,item.roleCode,index)}"
                         @focus="getItemRoleCodeOptionArr('',item.roleCodeList,index)"
                          filterable v-model="item.roleCodeList" clearable multiple >
                <el-option v-for="(item2,index2) in item.itemRoleCodeOptionArr" :key="index2" :label="item2.label"
                           :value="item2.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col> -->

        </el-row>

      </div>

    </el-form>
    <!--<div class="dialog-footer">-->
      <!--<el-button-->
        <!--v-if="formConfig.btnCode !== 'view'"-->
        <!--type="primary"-->
        <!--@click="submitInfoObj"-->
        <!--size="small"-->
        <!--icon="el-icon-check"-->
      <!--&gt;提交-->
      <!--</el-button>-->
      <!--<el-button-->
        <!--type="danger"-->
        <!--@click="()=>{$emit('onClose')}"-->
        <!--size="small"-->
        <!--icon="el-icon-close"-->
      <!--&gt;关闭-->
      <!--</el-button>-->
    <!--</div>-->
  </div>
</template>

<script>
import request from '@/found/utils/request';
// import Password from '@/found/components/form/components/form_password.vue';

export default {
  // extends: Form,
  name: 'addUser',
  props: {
    id: String,
    row: Object,
    formConfig: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Array,
      default: () => ([
        {
          terminalCode: '',
          itemterminalCodeOptionArr: [],
          roleCodeList: [],
          itemRoleCodeOptionArr: [],
        },
      ]),
    },
  },
  components: {
    // Password,
  },
  data() {
    return {
      userCanRelationManyCustomer: 'Y', // Y/N 客户用户是否可以关联多个客户，Y表示可以关联多个，N表示最多只能关联一个
      passwordDefault: '******',
      infoObj: {
        id: '',
        userType: '',
        fullName: '',
        gender: '',
        userPhone: '',
        userName: '',
        userPassword: '',
        email: '',
        startTime: '',
        endTime: '',
        terminalCode: '',
        roleCode: '',
        terminalList: [
          {
            terminalCode: '',
            itemterminalCodeOptionArr: [],
            roleCodeList: [],
            itemRoleCodeOptionArr: [],
          },
        ],

      },
      showPassword: true,
      findCustomerSelectListArr: [], // 关联客户的下拉选项 数组
      roleSelectListArr: [], // 角色的下拉选项 数组
      // 操作类型
      operationTypeOptionArr: [],
      // 是否主职位
      primaryFlagOptionArr: [],
      positionCanUsedToRelateUserListArr: [], // 职位的下拉选项 数组
      genderOptionArr: [], // 用户类型下拉选项
    };
  },
  watch: {
    'infoObj.terminalList': {
      deep: true,
      immediate: true,
      handler(terminalList) {
        this.$emit('input', terminalList);
        this.$emit('change', terminalList);
      },
    },
  },
  created() {
    this.getCustomerUserControl();
    // this.getUserInfoObjDetails(this.formConfig.id);
    // if (this.formConfig && this.formConfig.id) {
    //   this.getUserInfoObjDetails(this.formConfig.id);
    // }
  },
  mounted() {},
  methods: {
    // 密码框change事件
    passwordChange(e) {
      if (!e) {
        this.showPassword = false;
        setTimeout(() => {
          this.showPassword = true;
          this.$nextTick(() => {
            this.$refs.password.focus();
          });
        }, 1);
      }
    },
    // 获取客户用户新增编辑页面控制变量
    getCustomerUserControl() {
      request.get('/mdm/mdmTerminalUserController/getTerminalUserControl').then((res) => {
        if (res.success) {
          this.userCanRelationManyCustomer = res.result.userCanRelationManyTerminal;
          if (this.formConfig && this.formConfig.id) {
            this.getUserInfoObjDetails(this.formConfig.id);
          }
        }
      });
    },

    //  获取多客户关联  每一项 下拉选项

    getItemterminalCodeOptionArr(name, selectedCode, index) {
      console.log(28333333, name, selectedCode, index);
      request.post('/mdm/mdmTerminalController/select', {
        userName: this.infoObj.userName,
        enableStatus: '009',
        unionName: name,
        selectedCode,
      }).then((res) => {
        if (res.success) {
          this.$nextTick(() => {
            this.infoObj.terminalList[index].itemterminalCodeOptionArr = res.result.map((a) => ({
              label: a.unionName,
              value: a.terminalCode,
            }));
            return res.result.map((a) => ({ label: a.unionName, value: a.terminalCode }));
          });
        } else {
          return [];
        }
      });
    },
    getItemRoleCodeOptionArr(name, selectedCodeList, index) {
      request.post('/mdm/mdmRoleController/roleSelectList', {
        enableStatus: '009',
        roleName: name,
        selectedCodeList,
      }, { headers: { functionCode: 'select-permissions-role' } }).then((res) => {
        if (res.success) {
          this.$nextTick(() => {
            this.infoObj.terminalList[index].itemRoleCodeOptionArr = res.result.map((a) => ({
              label: a.roleName,
              value: a.roleCode,
            }));
            return res.result.map((a) => ({ label: a.roleName, value: a.roleCode }));
          });
        } else {
          return [];
        }
      });
    },

    // 根据 id  获取 详情
    async getUserInfoObjDetails(id = '') {
      request.get('/mdm/mdmTerminalUserController/detailById', { id }).then((res) => {
        if (res.success) {
          if (res.result.terminalList.length === 0) {
            this.infoObj = {
              ...res.result,
              terminalList: [
                {
                  terminalCode: '',
                  itemterminalCodeOptionArr: [],
                  roleCode: '',
                  itemRoleCodeOptionArr: [],
                },
              ],
            };
          } else {
            this.infoObj = {
              ...res.result,
              terminalList: res.result.terminalList.map((a) => ({
                ...a,
                itemterminalCodeOptionArr: [],
                itemRoleCodeOptionArr: [],
              })),
            };
            console.log(this.infoObj);
            this.infoObj.terminalList.forEach((a, index) => {
              this.getItemterminalCodeOptionArr('', a.terminalCode, index);
              this.getItemRoleCodeOptionArr('', a.roleCode, index);
            });
          }
        }
      });
    },

    //  一对一  关联客户改变  terminalCodeSelectedChange

    terminalCodeSelectedChange(code) {
      if (!this.infoObj.id) {
        this.infoObj.userName = code;
      }
      this.infoObj.fullName = this.findCustomerSelectListArr.find((a) => a.value === code).label;
    },
    // 获取关联客户下拉
    getFindCustomerSelectListArr(name, selectedCode) {
      request.post('/mdm/mdmTerminalController/select', {
        userName: this.infoObj.userName,
        enableStatus: '009',
        terminalName: name,
        selectedCode,
      }).then((res) => {
        if (res.success) {
          this.findCustomerSelectListArr = res.result.map((a) => ({ label: a.terminalName, value: a.terminalCode }));
        }
      });
    },

    // 获取角色下拉
    getRoleSelectListArr(name, selectedCode) {
      request.post('/mdm/mdmRoleController/roleSelectList', {
        enableStatus: '009',
        roleName: name,
        selectedCode,
      }, { headers: { functionCode: 'select-permissions-role' } }).then((res) => {
        if (res.success) {
          this.roleSelectListArr = res.result.map((a) => ({ label: a.roleName, value: a.roleCode }));
        }
      });
    },

    submitInfoObj() {
      let isRight = false;
      this.$refs.infoObjFormRef.validate((valid) => {
        if (valid) {
          isRight = valid;
        }
      });
      return isRight;
    },

    //  新增每一i想
    addArrItem(userType, arr) {
      arr.push(
        {
          terminalCode: '',
          itemterminalCodeOptionArr: [],
          roleCode: '',
          itemRoleCodeOptionArr: [],
        },
      );
    },
    // 删除每一项
    delArrItem(userType, arr, index) {
      if (arr.length <= 1) {
        this.$message.error('必须保留一条数据');
        return;
      }
      this.$confirm('确认删除？', '提示').then(() => {
        arr.splice(index, 1);
      });
    },

  },

};
</script>

<style scoped lang="less">
  /deep/ .el-form-item__label {
    font-size: 12px !important;
  }

  /deep/ .el-button--small.is-circle {
    padding: 3px;
  }

  .addUser-position-information {

    .footer-btn {
      position: absolute;
      bottom: 0;
      right: 10px;

      button {
        width: 100px;
      }
    }

    .arrItemTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .arrItemTitle-left {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: 0 20px 0 0;
          font-size: 16px;
          height: 20px;
          line-height: 20px;
          font-weight: 600;
        }

      }

      .arrItemTitle-right {

      }

    }

  }

</style>
