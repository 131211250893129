<template>
  <div class="home">
    <TablePage :treeCode="treeCode" @resetTrees="reset" ref="table" />
  </div>
</template>

<script>
import TablePage from './table';
import request from '../../../../utils/request';

export default {
  name: 'Home',
  components: {
    TablePage,
    // TablePageTree,
  },
  created() {
  },
  computed: {},
  methods: {
    treeChange(code) {
      this.treeCode = code;
      this.$refs.table.tablePage.currentPage = 1;
    },
    // 重置
    reset() {
      if (this.treeCode === '') {
        this.$refs.table.getList();
      }
      this.treeCode = '';
    },
    // 生成配置
    createConfig() {
      request.post('/mdm/mdmcolumnconfig/save_batch', {});
    },
  },

  data() {
    return {
      treeCode: '',
      treeValue: '',
    };
  },
};
</script>
<style lang="less" scoped></style>
