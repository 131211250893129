<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import terminalList from './components/terminalList/terminalList.vue';
import password from '../../../../../../components/form/components/form_password.vue';

formCreate.component('terminalList', terminalList);
formCreate.component('password', password);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      userCanRelationManyCustomer: 'Y', // Y/N 终端用户是否可以关联多个终端，Y表示可以关联多个，N表示最多只能关联一个
      passwordDefault: '******',
      infoObj: {
        id: '',
        userType: '',
        fullName: '',
        gender: '',
        userPhone: '',
        userName: '',
        userPassword: '',
        email: '',
        startTime: '',
        endTime: '',
        customerCode: '',
        roleCodeList: '',
        // terminalList: [
        //   {
        //     customerCode: '',
        //     itemCustomerCodeOptionArr: [],
        //     roleCode: '',
        //     itemRoleCodeOptionArr: [],
        //   },
        // ],

      },
      showPassword: true,
      findCustomerSelectListArr: [], // 关联客户的下拉选项 数组
      roleSelectListArr: [], // 角色的下拉选项 数组
      // 操作类型
      operationTypeOptionArr: [],
      // 是否主职位
      primaryFlagOptionArr: [],
      positionCanUsedToRelateUserListArr: [], // 职位的下拉选项 数组
      genderOptionArr: [], // 用户类型下拉选项
    };
  },
  created() {
    this.getCustomerUserControl();
  },
  mounted() {},
  methods: {

    setRule(v) {
      const item = v;
      if (item.field === 'roleCodeList') {
        item.restful = '/mdm/mdmRoleController/roleSelectList';
        item.headers = { functionCode: 'select-permissions-role' };
        item.restfulParams = { enableStatus: '009', selectedCode: this.infoObj.roleCode };
        item.optionsKey = { label: 'unionName', value: 'roleCode' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          multiple: true,
          remoteParams: 'roleName',
        };
      }
      return item;
    },

    async getFromRule() {
      const res = await this.getFormRule('user_terminal_management_add_onetoone');
      const userName = this.getRule('userName');
      res.forEach((item) => {
        if (item.field === 'endTime') {
          Object.assign(item, {
            ...this.props,
            props: {
              type: 'datetime',
              valueFormat: 'yyyy-MM-dd HH:mm:ss',
              defaultTime: '23:59:59',
              relation: {
                beginField: 'startTime',
                endField: 'endTime',
                gtNow: true,
              },
            },
          });
        }
        if (item.field === 'startTime') {
          Object.assign(item, {
            ...this.props,
            props: {
              type: 'datetime',
              valueFormat: 'yyyy-MM-dd HH:mm:ss',
              relation: {
                beginField: 'startTime',
                endField: 'endTime',
                gtNow: true,
              },
            },
          });
        }
        if (item.field === 'userPassword') {
          Object.assign(item, {
            ...this.props,
            props: {
              placeholder: '密码',
            },
          });
        }
      });
      const terminalListCom = this.getRule('terminalList');
      const userPhone = this.getRule('userPhone');
      const customerCode = this.getRule('terminalCode');
      // const roleCode = this.getRule('roleCodeList');
      // if (roleCode) {
      //   roleCode.restful = '/mdm/mdmRoleController/roleSelectList';
      //   roleCode.headers = { functionCode: 'select-permissions-role' };
      //   roleCode.restfulParams = { enableStatus: '009', selectedCode: this.infoObj.roleCode };
      //   roleCode.optionsKey = { label: 'unionName', value: 'roleCode' };
      //   roleCode.props = {
      //     ...roleCode.props,
      //     filterable: true,
      //     remote: true,
      //     multiple: true,
      //     remoteParams: 'roleName',
      //   };
      // }

      // 编辑或查看
      if (this.formConfig.id) {
        // this.getUserInfoObjDetails(this.formConfig.id);

        request.get('/mdm/mdmTerminalUserController/detailById', { id: this.formConfig.id }).then((res2) => {
          if (res2.success) {
            const { result } = res2;

            delete result.terminalList;

            this.infoObj = {
              ...result,
              userPassword: this.passwordDefault,
            };
            setTimeout(() => {
              this.setValue(this.infoObj);
            }, 100);
            console.log(this.userCanRelationManyCustomer);
            if (this.userCanRelationManyCustomer === 'Y') {
              this.hiddenFields(true, 'terminalCode');
              // this.hiddenFields(true, 'roleCode');

              if (terminalListCom) {
                terminalListCom.props = {
                  ...terminalListCom.props,
                  formConfig: this.formConfig,
                  propsInfoObj: this.infoObj,
                };
              }
            } else {
              this.hiddenFields(true, 'terminalList');
              if (customerCode) {
                customerCode.restful = '/mdm/mdmTerminalController/select';
                customerCode.restfulParams = { enableStatus: '009', selectedCode: this.infoObj.terminalCode, userName: this.infoObj.userName };
                customerCode.optionsKey = { label: 'unionName', value: 'terminalCode' };
                customerCode.props = {
                  ...customerCode.props,
                  filterable: true,
                  remote: true,
                  // multiple: true,
                  remoteParams: 'customerName',
                };
                customerCode.on.change = (code) => {
                  // console.log(118, customerCode);

                  // if (!this.infoObj.id) {
                  //   this.setValue({
                  //     userName: code,
                  //   });
                  // }
                  // this.setValue({
                  //   fullName: customerCode.options.find((a) => a.value === code).label,
                  // });
                };
              }
            }
            this.reload(this.rule);
          }
        });

        if (userName) {
          userName.props = {
            ...userName.props,
            disabled: true,
          };
        }
      }

      // 存在 对客户信息信息赋值
      if (this.userCanRelationManyCustomer === 'Y') {
        console.log(this.userCanRelationManyCustomer);
        this.hiddenFields(true, ['terminalCode']);
        if (terminalListCom) {
          terminalListCom.props = {
            ...terminalListCom.props,
            formConfig: this.formConfig,
            propsInfoObj: this.infoObj,
          };
        }
      } else {
        this.hiddenFields(true, 'terminalList');
        if (customerCode) {
          customerCode.restful = '/mdm/mdmTerminalController/select';

          customerCode.restfulParams = { enableStatus: '009', selectedCode: this.infoObj.terminalCode, userName: this.infoObj.unionName };
          customerCode.optionsKey = { label: 'unionName', value: 'terminalCode' };
          customerCode.props = {
            ...customerCode.props,
            filterable: true,
            remote: true,
            // multiple: true,
            remoteParams: 'unionName',
          };
          customerCode.on.change = (code) => {
            console.log(118, customerCode);

            // if (!this.infoObj.id) {
            //   this.setValue({
            //     userName: code,
            //   });
            // }
            // this.setValue({
            //   fullName: customerCode.options.find((a) => a.value === code).label,
            // });
          };
        }
        // if (roleCode) {
        //   roleCode.restful = '/mdm/mdmRoleController/roleSelectList';
        //   roleCode.headers = { functionCode: 'select-permissions-role' };
        //   roleCode.restfulParams = { enableStatus: '009', selectedCode: this.infoObj.roleCode };
        //   roleCode.optionsKey = { label: 'roleName', value: 'roleCode' };
        //   roleCode.props = {
        //     ...roleCode.props,
        //     filterable: true,
        //     remote: true,
        //     // multiple: true,
        //     remoteParams: 'roleName',
        //   };
        // }
      }

      // 电话号码
      if (userPhone) {
        userPhone.props = {
          ...userPhone.props,
          maxlength: 11,
        };
        userPhone.validate = [
          ...userPhone.validate,
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号', trigger: 'change' },
        ];
      }

      this.rule = res;
      this.reload(this.rule);
    },

    // 密码框change事件
    passwordChange(e) {
      if (!e) {
        this.showPassword = false;
        setTimeout(() => {
          this.showPassword = true;
          this.$nextTick(() => {
            this.$refs.password.focus();
          });
        }, 1);
      }
    },
    // 获取客户用户新增编辑页面控制变量
    getCustomerUserControl() {
      // request.get('/mdm/mdmTerminalUserController/getTerminalUserControl').then((res) => {
      //   if (res.success) {
      //     this.userCanRelationManyCustomer = res.result.userCanRelationManyTerminal;
      //   }
      // });
      this.getFromRule();
    },

    submit() {
      if (this.userCanRelationManyCustomer === 'Y') {
        const isRight = this.fApi.method('terminalList', 'submitInfoObj')();
        if (!isRight) {
          return false;
        }
      }

      const formData = this.getFormData();
      console.log(3722222, formData);

      if (formData) {
        this.infoObj = {
          ...this.infoObj,
          ...formData,
        };
        // 默认新增
        let url = '/mdm/mdmTerminalUserController/save';
        // 有id  就是编辑
        if (this.infoObj.id) {
          url = '/mdm/mdmTerminalUserController/update';
          this.infoObj.userPassword = this.infoObj.userPassword === this.passwordDefault ? '' : this.infoObj.userPassword;
        }
        const params = this.infoObj;
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },

  },
};
</script>
