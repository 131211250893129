var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          ref: "infoObjFormRef",
          attrs: { rules: _vm.rules, model: _vm.infoObj },
        },
        [
          _c(
            "el-form-item",
            {
              staticClass: "flex",
              attrs: { label: "新密码", prop: "password" },
            },
            [
              _c("Password", {
                attrs: { placeholder: "密码" },
                model: {
                  value: _vm.infoObj.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.infoObj, "password", $$v)
                  },
                  expression: "infoObj.password",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }