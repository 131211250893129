<script>
import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import resetPassword from './components/reset_password/reset_password.vue';
import TerminalList from './add_user/components/terminalList/terminalList';

formCreate.component('resetPassword', resetPassword);
formCreate.component('terminalList', TerminalList);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  props: {
    formConfig: Object,
  },
  data() {
    return {
      rule: [], // 渲染表单
    };
  },
  async created() {
    const rule = await this.getBeforFormRule('reset_password');
    const newRule = [];
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'reset_password') {
        newRule.push({
          type: 'resetPassword',
          field: 'reset_password',
          title: '',
          value: {},
        });
      } else {
        newRule.push(v);
      }
    });
    this.rule = newRule;
    this.reload(this.rule);
  },
  methods: {
    submit() {
      if (this.getFormData().reset_password.password) {
        const params = {
          password: this.getFormData().reset_password.password,
          ids: this.formConfig.selectRow.map((a) => a.id),
        };
        request.post('/mdm/mdmCustomerUserController/forceChangePassword', params).then((res) => {
          console.log(788888, res);
          if (res.success) {
            this.$message.success(res.message);
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      } else {
        this.$message.error('新密码不能为空');
      }
    },

  },
};
</script>
